<template>
    <div class="content">
        <!--        公共导航-->
        <commonTitle></commonTitle>
        <div class="search">
            <el-form ref="form" :model="form" label-width="120px" :inline="true">
                <el-form-item label="请输入项目名称:">
                    <el-input v-model="form.name" placeholder="请输入项目名称" size="mini"></el-input>
                </el-form-item>
                <button @click.prevent="common()" style="cursor: pointer;">搜索</button>
            </el-form>
        </div>
        <el-card class="card">
            <el-table
                    :data="tableData"
                    stripe
                   
                    style="width: 100%"
                    v-loading="loading"
                    element-loading-text="拼命加载中"
            >
                <el-table-column
                        prop="orgName"
                        label="物业单位" show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                        prop="name"
                        label="项目名称" show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                        prop="type"
                        label="维修类型" show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                        prop="object"
                        label="维修对象" show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                        prop="scale"
                        label="维修规模" show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                        prop="money"
                        label="分摊金额" show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                        prop="voteResult"
                        label="投票状态"
                        :formatter="formatVoteResult">
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button
                                size="mini"
                                @click="goVote(scope.$index, scope.row)"
                                :disabled="show(scope.row.voteResult)"
                                type="text"
                        >去投票
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!--            分页-->
            <el-pagination
                    class="page"
                    background
                    layout="prev, pager, next"
                    :page-size="15"
                    @current-change="handleCurrentChange"
                    :total="totalNum">
            </el-pagination>
        </el-card>
    </div>
</template>

<script>
    import commonTitle from "../../commonTitle";

    export default {
        name: "touPiaoList",
        components: {
            commonTitle
        },
        data() {
            return {
                tableData: [],
                url: this.$Config.base_server,
                loading: false,
                totalNum: 15,
                form: {
                    name: "",
                    region: '',
                },
                data: {
                    limit: 15,
                }
            }
        }, mounted() {
            this.common();
        }, methods: {
            common(currentPage = 0) {
                //初始化数据
                //查询
                const _this = this;
                _this.loading = true;

                _this.data.page = currentPage;
                _this.data.name = _this.form.name;
                $.ajax({
                    url: _this.url + "/api-public/vote/list?access_token=" + localStorage.getItem("token"),
                    type: 'get',
                    data: _this.data,
                    success: function (res) {
                        _this.loading = false;
                        _this.tableData = res.data;
                        _this.totalNum = res.count;
                    }
                });
            },
            goVote(index, row) {
                //本地存储投票信息
                localStorage.setItem("vote", JSON.stringify(row));
                this.$router.push({"name": "touPiaoDetail"});
            }, formatVoteResult(row) {
                //格式化投票结果
                if (row.voteResult == 0) {
                    return "同意";
                } else if (row.voteResult == 1) {
                    return "不同意";
                } else if (row.voteResult == 2) {
                    return "未投票";
                }
            }, show(val) {
                //控制投票按钮
                if (val == 0) {
                    return true;
                } else {
                    return false;
                }
            }, handleSizeChange(val) {
                console.log('每页${val}条');
                this.pagesize = val;
                this.common(val);
            },
            handleCurrentChange(val) {
                console.log('当前页' + val);
                this.currpage = val;
            }
        }
    }
</script>

<style scoped lang="scss">
    .content {
        width: 1200px;
        padding: 27px 111px 109px;;
        margin: 0 auto;
        background: #fff;
        min-height: 400px;

        .card {
            margin-top: 0px;

            .page {
                margin-top: 20px;
            }
        }

    }
</style>